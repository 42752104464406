import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { sendGetRequest } from "../../Common/sendGetRequest";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import Stack from "@mui/material/Stack";
import noteImage from "../../Assets/note.png";
import Spinner from "../../Common/Spinner";
import EditCategories from "./EditCategories";
import CreateCategories from "./CreateCategories";
import { useActiveNavigation } from "../Sidebar/useActiveNavigation";
import { WelcomeAndThanksText } from "../Surveys/WelcomeAndThanksText";
import SaveButton from "../../Common/SaveButton";
import { sendPostRequest } from "../../Common/sendPostRequest";
import { showToaster } from "../../Common/showToaster";

export default function Texts() {
  const [dataFetched, setDataFetched] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [texts, setTexts] = React.useState({
    welcome: "",
    welcomeEnglish: "",
    thanks: "",
    thanksEnglish: "",
  });
  const [internalTexts, setInternalTexts] = React.useState({
    welcome: "",
    welcomeEnglish: "",
    thanks: "",
    thanksEnglish: "",
  });

  let combinedTexts = { texts: texts, internalTexts: internalTexts };
  useActiveNavigation({ activePage: "settings", activeSubPage: "texts" });

  function handleSubmit() {
    console.log("combined", combinedTexts);
    sendPostRequest("updateTexts.php", combinedTexts).then((response) => {
      if (response.includes("Änderungen wurden erfolgreich gespeichert")) {
        showToaster(
          response,
          "Error",
          "Änderungen wurden erfolgreich gespeichert"
        );
        setLoading(false);
        setSuccess(true);
      } else {
        showToaster(
          response,
          "Ein unerwarteter Fehler ist aufgetreten",
          "Änderungen wurden erfolgreich gespeichert"
        );
        setLoading(false);
        setSuccess(false);
      }
    });
  }

  return (
    <>
      <ToastContainer />
      <Stack
        className="header"
        direction="row"
        spacing={{ xs: 2, sm: 1, md: 2 }}
      >
        <img className="pageIcon" src={noteImage} alt="checkmark" />
        <div>
          <h5>Texte</h5>
          <p className="siteInfo">
            Verwalte Standard Texte die vor und nach Feedback Abgabe angezeigt
            werden
          </p>
        </div>
      </Stack>
      <Fade in={true}>
        <Paper className="paper" style={{ maxWidth: "800px" }}>
          <Stack spacing={2}>
            <b>Texte für Kursteilnehmer Feedback</b>
            <WelcomeAndThanksText
              texts={texts}
              setTexts={setTexts}
              surveyId={0}
              target={"Kursteilnehmer"}
              setDataFetched={setDataFetched}
            />
            <br />
            <b>Texte für internes Feedback</b>
            <WelcomeAndThanksText
              texts={internalTexts}
              setTexts={setInternalTexts}
              surveyId={0}
              target={"Intern"}
              setDataFetched={setDataFetched}
            />
            <SaveButton
              handleSubmit={handleSubmit}
              loading={loading}
              setLoading={setLoading}
              success={success}
            />
          </Stack>
        </Paper>
      </Fade>
    </>
  );
}
