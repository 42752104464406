import jwt_decode from "jwt-decode";
import { getCookie } from "../Common/getCookie";

export function getUserRoleFromJWT() {
  try {
    var jwt = getCookie("jwt");
    var decoded = jwt_decode(jwt);
    let Role = decoded.Role;
    return Role;
  } catch (e) {
    window.location.href = "/login";
  }
}
