import { sendGetRequest } from "../../Common/sendGetRequest";
import { sendGetRequestWoToken } from "../../Common/sendGetRequestWoToken";
import { sendPostRequest } from "../../Common/sendPostRequest";
import { showToaster } from "../../Common/showToaster";

export function deleteValidationErrors(questionData) {
  const errors = {};

  Object.entries(questionData).forEach(([key, value]) => {
    errors[`error${key.charAt(0).toUpperCase()}${key.slice(1)}`] = false;
  });
}

export function validateInputFields(
  questionData,
  setErrorQuestionData,
  updateState
) {
  var isValid = true;
  if (
    parseInt(questionData.minimum) >= parseInt(questionData.maximum) ||
    isNaN(questionData.minimum) ||
    isNaN(questionData.maximum)
  ) {
    updateState("errorValidRange", true, setErrorQuestionData);

    isValid = false;
  }
  if (questionData.selectedOption === "") {
    updateState("errorSelectedOption", true, setErrorQuestionData);
    isValid = false;
  }
  if (questionData.target === "") {
    updateState("errorTarget", true, setErrorQuestionData);
    isValid = false;
  }
  if (questionData.description === "") {
    updateState("errorDescription", true, setErrorQuestionData);
    isValid = false;
  }
  if (questionData.descriptionEnglish === "") {
    updateState("errorDescriptionEnglish", true, setErrorQuestionData);
    isValid = false;
  }
  if (
    (questionData.selectedOption == 1 ||
      questionData.selectedOption == 2 ||
      questionData.selectedOption == 5 ) &&
    questionData.customOptions == ""
  ) {
    updateState("errorCustomOptions", true, setErrorQuestionData);
    isValid = false;
  }
  if (
    (questionData.selectedOption == 0 ||
      questionData.selectedOption == 3 ||
      questionData.selectedOption == 4 ) &&
    questionData.selectedSecondaryOption == null
  ) {
    updateState("errorSelectedSecondaryOption", true, setErrorQuestionData);
    isValid = false;
  }

  return isValid;
}

export function editQuestion(
  questionData,
  setSuccess,
  setLoading,
  updateQuestionList
) {
  sendPostRequest("editQuestion.php", questionData).then((response) => {
    if (response.includes("Änderungen wurden erfolgreich gespeichert")) {
      showToaster(
        response,
        "Ein unerwarteter Fehler ist aufgetreten",
        "Änderungen wurden erfolgreich gespeichert"
      );
      setSuccess(true);
      setLoading(false);
      updateQuestionList();
    } else {
      setLoading(false);
      showToaster(
        "Ein unerwarteter Fehler ist aufgetreten, bitte überprüfe die Internetverbindung",
        "Ein unerwarteter Fehler ist aufgetreten, bitte überprüfe die Internetverbindung",
        "Änderungen wurden erfolgreich gespeichert"
      );
    }
  });
}

export function createQuestion(
  questionData,
  setSuccess,
  setLoading,
  handleClose,
  addQuestionToSurvey,
  navigate
) {
  sendPostRequest("createQuestion.php", questionData).then((response) => {
    if (response.includes("Frage wurde erfolgreich gespeichert")) {
      showToaster(
        response,
        "Ein unerwarteter Fehler ist aufgetreten",
        "Frage wurde erfolgreich gespeichert"
      );
      setSuccess(true);
      setLoading(false);
      addQuestionToSurvey();
      try {
        handleClose();
      } catch (er) {
        localStorage.setItem(
          "toastMessage",
          "Frage wurde erfolgreich hinzugefügt"
        );
        navigate("../questions");
      }
    } else {
      setLoading(false);
      showToaster(
        "Ein unerwarteter Fehler ist aufgetreten, bitte überprüfe die Internetverbindung",
        "Ein unerwarteter Fehler ist aufgetreten, bitte überprüfe die Internetverbindung",
        "Änderungen wurden erfolgreich gespeichert"
      );
    }
  });
}

export function generateJson(
  questionData,
  setJson,
  SurveyJsQuestion,
  evaluationOptions,
  setQuestionData
) {
  const updatedJson = { ...SurveyJsQuestion };
  updatedJson.pages[0].elements[0].rateCount = evaluationOptions.length;
  updatedJson.pages[0].elements[0].rateMax = evaluationOptions.length;
  updatedJson.pages[0].elements[0].title.de = questionData.description;
  updatedJson.pages[0].elements[0].title.en = questionData.descriptionEnglish;
  updatedJson.pages[0].elements[0].description.de = questionData.help;
  updatedJson.pages[0].elements[0].description.en = questionData.helpEnglish;
  updatedJson.pages[0].elements[0].rangeMax =
    updatedJson.pages[0].elements[0].max = questionData.maximum;
  updatedJson.pages[0].elements[0].rangeMin =
    updatedJson.pages[0].elements[0].min = questionData.minimum;
  updatedJson.pages[0].elements[0].isRequired =
    questionData.isRequired == "1" ? 1 : 0;
  if (
    questionData.selectedOption == 0 &&
    questionData.selectedSecondaryOption
  ) {
    updatedJson.pages[0].elements[0].type = "rating";
    updatedJson.pages[0].elements[0].rateMin = 1;
    updatedJson.pages[0].elements[0].rateMax = 5;
    updatedJson.pages[0].elements[0].rateType =
      questionData.selectedSecondaryOption;
    if (questionData.selectedSecondaryOption === "radiogroup") {
      updatedJson.pages[0].elements[0].type =
        questionData.selectedSecondaryOption;
      updatedJson.pages[0].elements[0].choices = evaluationOptions.map(
        (option) => ({
          value: parseInt(option.ID),
          text: {
            de: option.Answer,
            en: option.AnswerEnglish,
          },
        })
      );
    }
    setJson(updatedJson);
  } else if (
    questionData.selectedOption == 1 ||
    questionData.selectedOption == 2 ||
    questionData.selectedOption == 5
  ) {
    if (questionData.selectedOption == 1)
      updatedJson.pages[0].elements[0].type = "radiogroup";
    else if (questionData.selectedOption == 2)
      updatedJson.pages[0].elements[0].type = "checkbox";
    else if (questionData.selectedOption == 5)
      updatedJson.pages[0].elements[0].type = "ranking";
    updatedJson.pages[0].elements[0].choices = questionData.customOptions;
    setJson(updatedJson);
  } else if (
    questionData.selectedOption == 3 &&
    questionData.selectedSecondaryOption
  ) {
    updatedJson.pages[0].elements[0].type =
      questionData.selectedSecondaryOption;
    if (questionData.selectedSecondaryOption == "text") {
      updatedJson.pages[0].elements[0].maxLength = 100;
      updatedJson.pages[0].elements[0].inputType = "text";
    } else {
      updatedJson.pages[0].elements[0].maxLength = 1000;
    }
    setJson(updatedJson);
  } else if (
    questionData.selectedOption == 4 &&
    questionData.selectedSecondaryOption
  ) {
    updatedJson.pages[0].elements[0].type =
      questionData.selectedSecondaryOption;
    if (questionData.selectedSecondaryOption === "number") {
      updatedJson.pages[0].elements[0].type = "text";
      updatedJson.pages[0].elements[0].inputType = "number";
    } else {
      updatedJson.pages[0].elements[0].type = "nouislider";
      updatedJson.pages[0].elements[0].name = "Frage undefiniert";
      updatedJson.pages[0].elements[0].inputType = "range";
    }
    setJson(updatedJson);
  } else if (questionData.selectedOption == 6) {
    updatedJson.pages[0].elements[0].type = "rating";
    updatedJson.pages[0].elements[0].rateType = "label";
    updatedJson.pages[0].elements[0].name = "Frage undefiniert";
    updatedJson.pages[0].elements[0].rateMin = 0;
    updatedJson.pages[0].elements[0].rateMax = 10;
    setJson(updatedJson);
  } else {
    setJson(null);
  }
  updateState("json", updatedJson.pages[0].elements[0], setQuestionData);
}

export const updateState = (variableName, value, setData) => {
  setData((prevState) => ({
    ...prevState,
    [variableName]: value,
  }));
};

export function getSurveyRelatedQuestions(
  surveyId,
  surveyPage,
  setSurveyData,
  maxpages
) {
  sendGetRequestWoToken(
    "getSurveyRelatedQuestions.php?surveyId=" +
      surveyId +
      "&surveyPage=" +
      surveyPage
  ).then((response) => updateState("errorSurveyName", response, setSurveyData));
}

export function getSurveyNotRelatedQuestions(surveyId, setRows) {
  sendGetRequest("getSurveyNotRelatedQuestions.php?surveyId=" + surveyId).then(
    (response) => setRows(response)
  );
}

export function getAllQuestions(setRows, setDataFetched) {
  sendGetRequest("getQuestions.php").then(
    (response) => setRows(response) + setDataFetched(true)
  );
}
