import { useContext, useEffect } from "react";
import { ActiveNavigationContext } from "./Sidebar";

export function useActiveNavigation(activeNavigationNew) {
  const { activeNavigation, setActiveNavigation } = useContext(
    ActiveNavigationContext
  );
  useEffect(() => {
    if (
      activeNavigation.activePage !== activeNavigationNew.activePage ||
      activeNavigation.activeSubPage !== activeNavigationNew.activeSubPage
    ) {
      setActiveNavigation({
        activePage: activeNavigationNew.activePage,
        activeSubPage: activeNavigationNew.activeSubPage,
      });
    }
  });
}
