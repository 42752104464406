import { createContext, useEffect, useState } from "react";
import { sendGetRequest } from "../../Common/sendGetRequest";

export const UserContext = createContext({});

export const UserProvider = ({ children }) => {
  const [currentUserData, setCurrentUserData] = useState({
    id: 0,
    id: 0,
    name: "",
    vorname: "",
    nachname: "",
    username: "",
    email: "",
    role: 0,
    description: "ohne Berechtigung",
    avatarfile: "",
    notification: 0,
  });
  const [allFeedbackData, setAllFeedbackData] = useState([])
  const [dataFetched, setDataFetched] = useState(false)

  const updateCurrentUserState = (variableName, value) => {
    setCurrentUserData(prevState => ({
      ...prevState,
      [variableName]: value
    }));
  };

  useEffect(() => {

    try {
      sendGetRequest("getUserContext.php").then(
        (decoded) => {
          updateCurrentUserState('id', decoded.id)
          updateCurrentUserState('name', decoded.Vorname + " " + decoded.Nachname)
          updateCurrentUserState('vorname', decoded.Vorname)
          updateCurrentUserState('nachname', decoded.Nachname)
          updateCurrentUserState('username', decoded.Username)
          updateCurrentUserState('email', decoded.Email)
          updateCurrentUserState('role', decoded.Role)
          updateCurrentUserState('description', decoded.Description)
          updateCurrentUserState('notification', decoded.Notification)
          updateCurrentUserState('avatarfile', decoded.AvatarFile)
        })
    } catch (e) {
      console.log(e);
    }
  }, [])

  return (
    <UserContext.Provider value={{ currentUserData, allFeedbackData, updateCurrentUserState, dataFetched }}>{children}</UserContext.Provider>
  );
};
