import * as React from "react";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SidebarMenu from "./SidebarMenu";
import FeedcubeIcon from "../../Assets/FeedcubeIcon.svg"


export default function TemporaryDrawer(props) {
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    width: "300px",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  return (
    <>
      <Drawer open={props.open}>
        <DrawerHeader>
          <img className="logo" src={FeedcubeIcon} alt="logo" />
          <IconButton onClick={() => props.setOpen(false)}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <SidebarMenu setOpen={props.setOpen} />
      </Drawer>
      <div style={{ display: "none" }}>
        {" "}
        {/*Ist notwendig um die Verlinkungen aus dem Dashboard machen zu können */}
        <SidebarMenu />
      </div>
    </>
  );
}
