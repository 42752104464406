import {deleteCookie} from '../Common/deleteCookie';

export function logout() {
    deleteCookie('jwt');
    window.location.href = '/'
  }




