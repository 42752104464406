import { sendGetRequest } from "../../Common/sendGetRequest";
import { sendPostRequest } from "../../Common/sendPostRequest";
import { showToaster } from "../../Common/showToaster";

export function getSurveyDetails(setSurveyName, surveyId) {
  sendGetRequest("getSurveyDetails.php?id=" + surveyId).then((response) =>
    setSurveyName(response.Survey)
  );
}

export async function deleteSurvey (surveyId) {
  sendPostRequest(`deleteSurvey.php?id=${surveyId}`).then((response) => {
  });
};

export function deleteValidationErrors(questionData) {
  const errors = {};

  Object.entries(questionData).forEach(([key, value]) => {
    errors[`error${key.charAt(0).toUpperCase()}${key.slice(1)}`] = false;
  });
}

export function validateInputFields(questionData, setErrorQuestionData, updateState){
  var isValid=true;
  if(questionData.selectedOption==="")
  {
      updateState("errorSelectedOption", true, setErrorQuestionData)
      isValid=false
  }
  if(questionData.description==="")
  {
      updateState("errorDescription", true, setErrorQuestionData)
      isValid=false
  }
  if(questionData.descriptionEnglish==="")
  {
      updateState("errorDescriptionEnglish", true, setErrorQuestionData)
      isValid=false
  }
  if((questionData.selectedOption==1 || questionData.selectedOption==2 || questionData.selectedOption==5 || questionData.selectedOption==6) && questionData.customOptions=="")
  {
      updateState("errorCustomOptions", true, setErrorQuestionData)
      isValid=false
  }

    return isValid  
}

export function editSurvey(surveyData, surveyId, setSuccess, setLoading) {

  sendPostRequest("editSurvey.php?id="+surveyId, surveyData).then((response) => {
    if (!response.includes("Änderungen wurden erfolgreich gespeichert")) {
      setLoading(false)
      showToaster(
          "Ein unerwarteter Fehler ist aufgetreten, bitte überprüfe die Internetverbindung",
          "Ein unerwarteter Fehler ist aufgetreten, bitte überprüfe die Internetverbindung",
          "Änderungen wurden erfolgreich gespeichert"
        );
    }
  });
}

export function sortArray(array, order)
{
  var orderedArray=[]
  order.forEach(element => {
    orderedArray.push(array[element-1])
  });
  return orderedArray;
}

export function checkNoPagesEmpty(array, maxpage) {
  
  if (array.length === 0||array.length < maxpage) {
    return false; // Return false if the main array is empty
  }

  for (var subarray of array) {
    if (!subarray ||subarray.length===0) {
      return false; // Return false if any subarray is empty
    }
  }

  return true; // Return true if no empty subarray is found
}

export async function createSurvey(surveyData, setSuccess, setLoading, navigate) {
  
  sendPostRequest("createSurvey.php", surveyData).then((response) => {
    let responseObject = JSON.parse(response);
    if (responseObject.message.includes("Änderungen wurden erfolgreich gespeichert")) {
      showToaster(
        response,
        "Ein unerwarteter Fehler ist aufgetreten",
        "Änderungen wurden erfolgreich gespeichert"
      );
      setSuccess(true);
      setLoading(false);
      localStorage.setItem("toastMessage", "Fragebogen wurde erfolgreich angelegt");
      localStorage.setItem("activeStepSet", 1);
      navigate("../surveys/edit/"+responseObject.surveyId)
      return responseObject.surveyId
    }
    else{
      setLoading(false)
      showToaster(
          "Ein unerwarteter Fehler ist aufgetreten, bitte überprüfe die Internetverbindung",
          "Ein unerwarteter Fehler ist aufgetreten, bitte überprüfe die Internetverbindung",
          "Änderungen wurden erfolgreich gespeichert"
        );
    }
  });
}

export async function updateHeaders(surveyData, setSuccess, setLoading, surveyId) {

  if (surveyId){
    surveyData = {
      ...surveyData,
      surveyId: surveyId,
    };
  }
  
  sendPostRequest("updateSurveyHeaders.php", surveyData).then((response) => {
    if (!response.includes("Änderungen wurden erfolgreich gespeichert")) {
      showToaster(
          "Ein unerwarteter Fehler ist aufgetreten, bitte überprüfe die Internetverbindung",
          "Ein unerwarteter Fehler ist aufgetreten, bitte überprüfe die Internetverbindung",
          "Änderungen wurden erfolgreich gespeichert"
        );
    }
  });
}

export function removePage(page, surveyData, setSurveyData, pagenumber, setPagenumber, setPage, surveyId) {
  sendPostRequest(`removePageFromSurvey.php?surveyId=${surveyId}&page=${page}`).then((response) => {
    showToaster(
      "Seite wurde erfolgreich gelöscht",
      "Ein unerwarteter Fehler ist aufgetreten",
      "Seite wurde erfolgreich gelöscht"
    )
});
  var temparray = surveyData.surveyRelatedQuestionList.splice(parseInt(page)-1, 1);
  
  if(temparray.length>0 && temparray[0]!==undefined)
  {
    temparray = temparray[0].concat(surveyData.surveyUnrelatedQuestionList)
    temparray.sort((a, b) => a.Reihenfolge - b.Reihenfolge);
    updateState("surveyUnrelatedQuestionList", temparray, setSurveyData);
  }

  updateState("surveyRelatedQuestionList", surveyData.surveyRelatedQuestionList, setSurveyData);

  surveyData.headers.splice(parseInt(page)-1, 1);
  updateState("headers", surveyData.headers, setSurveyData);

  setPagenumber(pagenumber - 1);
  setPage(1);
}

export const updateState = (variableName, value, setData) => {
  setData((prevState) => ({
    ...prevState,
    [variableName]: value,
  }));
};

export function getAllSurveys(setRows, setDataFetched) {
  sendGetRequest("getSurveys.php").then(
    (response) => setRows(response) + setDataFetched(true)
  );
}